import React, { useState } from 'react';
import './App.css';
import BarebellCalculatorDisplay from './components/BarbellCalcuatorAndDisplay';
import NavBar from './components/NavBar'
import PlateInventoryModal from './components/PlateInventoryModal';
import BasebarbellModal from './components/BaseBarbellWeightChange';
import GenericModal from './components/GenericModal'
import AboutUs from './components/AboutUs';
const App = () => {
  const [modalOpen, setModalOpen] = useState("");
  const [availablePlates, setAvailablePlates] = useState([45, 25, 10, 5, 2.5]);
  const [barbellWeight, setBarbellWeight] = useState(45);
  const modalContentObject = {
    'about-us': AboutUs,
    'update-bar-weight': BasebarbellModal,
    'plate-inventory': PlateInventoryModal,
  }
  return (
    <div className="App">
          <div style={{height:'300px'}}>
        <div className="row background-banner">
        {/* <img src={banner} className='img-fluid'></img> */}
        </div>
        </div>
      <GenericModal 
      modalOpen={modalOpen}
       setModalOpen={setModalOpen}
        ModalContent={modalContentObject[modalOpen]}
        barbellWeight={barbellWeight}
        availablePlates={availablePlates}
        setAvailablePlates={setAvailablePlates}
        setBarbellWeight={setBarbellWeight}  
          />
      {/* //<PlateInventoryModal modalOpen={modalOpen} setModalOpen={setModalOpen} availablePlates={availablePlates} setAvailablePlates={setAvailablePlates}/>
      <BasebarbellModal modalOpen={modalOpen} setModalOpen={setModalOpen} setBarbellWeight={setBarbellWeight} barbellWeight={barbellWeight} /> */}
      <NavBar setModalOpen={setModalOpen} />
      <BarebellCalculatorDisplay barbellWeight={barbellWeight} availablePlates={availablePlates}/>
      </div>
  );
};

export default App;