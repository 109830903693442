import React, { useState, useEffect } from 'react';
import './barBellStyles.css';
import './barBellStyles.scss';
import DropdownButton from './utils/DropdownButton';
const BarbellCalcuatorAndDisplay = (props) => {
    const {availablePlates, barbellWeight} = props
    const [targetWeightInputValue, setTargetWeightInputValue] = useState(0);
  const [targetWeight, setTargetWeight] = useState(0);
  const [plateWeights, setPlateWeights] = useState([]);
  const handleWeightChange = (e) => {
    setTargetWeightInputValue(parseInt(e.target.value));
  };

  const plateColorMap = {
    55: 'red',
    45: 'blue',
    35: 'yellow',
    25: 'green',
    10: 'black',
    5: 'grey', 
    2.5: 'lilac'
  }
  useEffect(()=>{
    calculatePlates();
  }, [barbellWeight, availablePlates.length, targetWeight] )
  const calculatePlates = () => {
    setTargetWeight(targetWeightInputValue);
    const remainingWeight = targetWeight - barbellWeight; // Subtracting the barbell weight
    const plates = [];

    if (remainingWeight <= 0) {
      setPlateWeights([]);
      return;
    }

    let currentWeight = remainingWeight;

    for (let i = 0; i < availablePlates.length; i++) {
      const plate = availablePlates[i];
      const numPlates = Math.floor(currentWeight / (plate * 2));

      if (numPlates > 0) {
        for (let j = 0; j < numPlates; j++) {
          plates.push(plate);
        }
        currentWeight -= numPlates * plate * 2;
      }

      if (currentWeight === 0) {
        break;
      }
    }

    setPlateWeights(plates);
  }

  
  const handleAddPlate = (event) => {
   const  totalPlateWeightAdded = 2 * (event.target.value === 2 ? 2.5 : event.target.value);
    if (targetWeight >= barbellWeight) {
    setTargetWeight(targetWeight +totalPlateWeightAdded);
    setTargetWeightInputValue(targetWeight + totalPlateWeightAdded);
    }
    else {
      setTargetWeight(barbellWeight + totalPlateWeightAdded);
      setTargetWeightInputValue(barbellWeight + totalPlateWeightAdded);
  }      
  }
  const handleRemovePlate = (event) => {
    const plates = [...plateWeights];
    console.log('this is plates');
    console.log(event.target.value);
    let index = plates.indexOf(event.target.value === 2 ? 2.5 : event.target.value)
    console.log(index);
    plates.splice(index, 1);
    const newTargetValue = plates.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    setTargetWeight((newTargetValue * 2) + barbellWeight);
    setTargetWeightInputValue((newTargetValue * 2) + barbellWeight);
    setPlateWeights(plates);
  }
  return (
    <div className="App mt-3">
      <div className="input-container mt-3">
      <label htmlFor="weight-input">Current Bar Weight:</label>
      <input
          id="weight-input"
          type="number"
          min="0"
          value={barbellWeight}
          readOnly={true}
        />
        </div>
        <div className="input-container">
        <label htmlFor="weight-input" className='ml-5'>Target Weight (lbs):</label>
        <input
          id="weight-input"
          type="number"
          min="0"
          value={targetWeightInputValue}
          onChange={handleWeightChange}
        />
        <button className='mt-5' onClick={calculatePlates}>Calculate Plates</button>
      </div>
      <div className="plate-list">
        Plates Used:
        {plateWeights.length > 0 ?
         (
          <ul className="p-0">
            {plateWeights.map((weight, index) => (
              <li key={index}>{weight} lb</li>
            ))}
          </ul>
        ) 
        : (
          <p>No plates needed.</p>
        )}
        <div className='d-flex justify-content-center'>
        <DropdownButton  title={'Add Plate'} items={availablePlates} itemClick={handleAddPlate}/>
      <DropdownButton  title={'Remove Plate'} items={plateWeights} itemClick={handleRemovePlate} />
        </div>
              <div className="row col-12 justify-content-center mt-5">
    <div className="barbell d-flex col-6 col-xs-2 col-lg-2 align-items-center align-items-center">
        <div className="collar collarLeft"></div>
        <div className='d-inline-flex justify-content-center align-items-center'>
        {plateWeights.sort((a,b) => a - b).map((weight,index) => (
            <div key={index} style={{height: `${weight >= 45 ? '155px' : weight.value *5.3 + `px`}`, 'minHeight': '45px'}} className={`plate d-inline-flex justify-content-center align-items-center ${plateColorMap[weight] ? plateColorMap[weight] : "custom"}`}>
              <span className="plate-weight-number">{weight}</span>
              </div>

        ))}     
        </div>
      <div class="bar"></div>
    </div>
    </div>
          
      </div>
      </div>
  );
};

export default BarbellCalcuatorAndDisplay;