import React, {useState} from "react";


    /**
 * General Reuseable Dropdown function
 * @component
 * @example
 * const {string} title
 * const {Function} itemClick - What happens when user selects specific option 
 * const {Array.<{option: String, value: String}>} items 
 * 
 */

const DropdownButton = (props) => {
    const {items, itemClick, title} = props;
   
    const [showDropdown, setShowDropdown] = useState(false);
    const handleShowDropdown = () => {
        setShowDropdown(!showDropdown);
    };
    const handleItemClick = (e) => {
      console.log('THis is event on click', e.target)
      itemClick(e);
      setShowDropdown(!showDropdown);
  } 
        return (    
        <div className="dropdown d-inline m-1">
        <button className={`btn btn-secondary dropdown-toggle ${items.length === 0 ? 'disabled' : ''}`}type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleShowDropdown}>
          {title}
        </button>
        <ul className={`dropdown-menu ${showDropdown ? 'show' : '' }`} aria-labelledby="dropdownMenuButton1">
          { items.map((item, index) => {
            console.log('this is item rendered', item)
            return  <li className="dropdown-item" key={`${item}-${title}-${index}`} value={item} onClick={handleItemClick}>{item}</li>
           })}
        </ul>
      </div>)
}

export default DropdownButton;