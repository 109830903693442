import React, { useState } from 'react';
const NavBar = (props) => {
    const {setModalOpen} = props;
    const setModalTypeToBeOpen = (modalName) => {
        setModalOpen(modalName);
    }
    const [navBarShown, setNavBarShown] = useState(false);
    return (
        <div>
             <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
    <button onClickCapture={()=> {
        setNavBarShown(!navBarShown);
    }} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className={`${navBarShown ? 'show': '' } collapse navbar-collapse`} id="navbarNav">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" onClick={(()=> {setModalTypeToBeOpen('plate-inventory')})}>View/Update Plate Inventory</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" onClick={(()=> {setModalTypeToBeOpen('update-bar-weight')})}>Update Bar Weight</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" onClick={(()=> {setModalTypeToBeOpen('about-us')})}>About</a>
        </li>
      </ul>
    </div>
</nav>
</div>
        </div>
    )
}
export default NavBar