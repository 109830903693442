import React, { useState } from 'react';
const BasebarbellModal = (props) => {
    const {setModalOpen, modalOpen, barbellWeight, setBarbellWeight } = props;
    console.log('THIS IS BARBELL WEIGHT', barbellWeight);
    const [currentBarbellValue, setCurrentBarbellValue] = useState(barbellWeight);
    const closeModal = () => {
        setModalOpen('');
    }
    const handleBarbellWeightChange = () => {
        setBarbellWeight(currentBarbellValue);
        closeModal()
      }

      const handleCurrentBarbellValue = (e) => {
        setCurrentBarbellValue(e.target.value);
      }
    return (
<div className={`modal ${modalOpen === 'update-bar-weight' ? 'd-block' : ''}`} tabIndex="-1">
  <div className="modal-dialog modal-xl modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Update Default Weight of  Barbell</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <div className="input-container">
        <label htmlFor="weight-input">Base Barbell Weight (lbs):</label>
        <input
          id="barbell-weight-input"
          type="number"
          min="0"
          value={currentBarbellValue}
          onChange={handleCurrentBarbellValue}
        />
        <button onClick={handleBarbellWeightChange}>Change Barbell Weight</button>
      </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}>Close</button>
      </div>
    </div>
  </div>
</div>
    )
}
export default BasebarbellModal