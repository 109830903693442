import React, { useState } from 'react';
import weightPlateImage from '../assets/images/weight-plate.svg'
const PlateInventoryModal = (props) => {
    const {setModalOpen, modalOpen, availablePlates, setAvailablePlates} = props;
    const [newPlateAdded, setNewPlateValue] = useState(0);
    const closeModal = () => {
        setModalOpen('');
    }
    const handleNewPlateAdded = (e) => {
        setNewPlateValue(e.target.value);
      }

      const plateColorMap = {
        55: 'red-filter',
        45: 'blue-filter',
        35: 'yellow-filter',
        25: 'green-filter',
        10: 'black-filter',
        5: 'grey-filter', 
        2.5: 'lilac-filter'
      }
      const removePlate = (plateValue) => {
        const newPlates = availablePlates.filter((plate) => plate !== plateValue)
        setAvailablePlates(newPlates);
        }
        const addPlate = () => {
          const plateAddedInt = parseInt(newPlateAdded)
          if (plateAddedInt <= 0 || availablePlates.includes(plateAddedInt)) {
            return;
          }
          availablePlates.push(plateAddedInt);
          const newAvailablePlates = availablePlates.sort((a,b) => b-a);
          setAvailablePlates(newAvailablePlates);
        
          setNewPlateValue(0);
        }
    return (
<div className={`modal ${modalOpen === 'plate-inventory' ? 'd-block' : ''}`} tabIndex="-1">
  <div className="modal-dialog modal-xl modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
       
        <h5 className="modal-title">Update Plate Inventory</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <div>
        <div className="plates-available-container">
      <h2>Plates Available </h2>
      <div className='input-container mb-5'>
      <input
          id="add-weights-avaiable-input"
          type="number"
          min="0"
          value={newPlateAdded}
          onChange={handleNewPlateAdded}
        />
        <button onClick={addPlate}>Add Plate Available</button>
        </div>
        <div className="row">
        {availablePlates.map((plateValue) => (
          <div className="d-inline col-xs-1 col-sm-3 col-md-3 col-lg-2 border" key={plateValue}>
            <button 
            type="button" 
            className="btn-close btn btn-sm btn-close-focus-shadow float-end" 
            aria-label="Close"
            onClick={() => {
              return removePlate(plateValue)}}>
             <span aria-hidden="true">&times;</span>
            </button>
           <img className={`img-fluid ${plateColorMap[parseFloat(plateValue)]}`} src={weightPlateImage} alt="SVG Image" />
             <span>{plateValue}</span>
            </div>
        ))}
        </div>
        </div>
      </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}>Close</button>
      </div>
    </div>
  </div>
</div>
    )
}
export default PlateInventoryModal