import React, { useState } from 'react';
const GenericModal = (props) => {
    const {setModalOpen, modalOpen, ModalContent, modalTitle} = props;
    if (modalOpen === '') {
        return null;
    }
    const closeModal = () => {
        setModalOpen('');
    }
    return (
<div className={`modal d-block`} tabIndex="-1">
  <div className="modal-dialog modal-xl modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{modalTitle}</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <ModalContent {...props}/>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}>Close</button>
      </div>
    </div>
  </div>
</div>
    )
}
export default GenericModal